// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/login';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/'),
  authWithoutQR: '/login-without-qr',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, 'permission-denied'),
  blank: path(ROOTS_DASHBOARD, 'blank'),
  user: path(ROOTS_DASHBOARD, 'user'),
  becomePayer: path(ROOTS_DASHBOARD, 'onboarding/become-payer'),
  payers: {
    root: path(ROOTS_DASHBOARD, 'payers'),
    view: (id: string) => path(ROOTS_DASHBOARD, `payers/${id}`),
    viewExternal: (id: string) => path(ROOTS_DASHBOARD, `payers/network/${id}`),
  },
  billing: {
    root: path(ROOTS_DASHBOARD, 'invoices'),
  },
  wallets: {
    root: path(ROOTS_DASHBOARD, 'wallets'),
  },
  recipients: {
    root: path(ROOTS_DASHBOARD, 'recipients'),
    view: (id: string) => path(ROOTS_DASHBOARD, `recipients/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `recipients/edit/${id}`),
    new: path(ROOTS_DASHBOARD, `recipients/new`),
    massCreate: path(ROOTS_DASHBOARD, `recipients/mass-create`),
  },
  inbox: path(ROOTS_DASHBOARD, 'inbox'),
  payments: {
    root: path(ROOTS_DASHBOARD, 'payments'),
    new: path(ROOTS_DASHBOARD, 'payments/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `payments/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `payments/${id}`),
    transfer: path(ROOTS_DASHBOARD, 'payments/transfer'),
    massPayout: path(ROOTS_DASHBOARD, 'payments/mass-payout'),
    csvUpload: path(ROOTS_DASHBOARD, 'payments/csv'),
    quickPayout: path(ROOTS_DASHBOARD, 'payments/quick-payout')
  },
  admin: {
    root: path(ROOTS_DASHBOARD, 'a622aa7e-05b1-443f-95c7-6d6965426ed4/admin/overview'),
    invoices: path(ROOTS_DASHBOARD, 'a622aa7e-05b1-443f-95c7-6d6965426ed4/admin/invoices'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, 'invoice'),
  },
  overview: {
    root: path(ROOTS_DASHBOARD, 'overview'),
  },
  bills: {
    root: path(ROOTS_DASHBOARD, 'bills'),
    new: path(ROOTS_DASHBOARD, 'bills/new'),
    toPayer: path(ROOTS_DASHBOARD, 'bills/to-payer'),
    list: path(ROOTS_DASHBOARD, 'bills/list'),
    view: (id: string) => path(ROOTS_DASHBOARD, `bills/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `bills/${id}/edit`),
  },
  error: {
    serverError: path(ROOTS_DASHBOARD, '500'),
    notFound: path(ROOTS_DASHBOARD, '404'),
    accessDenied: path(ROOTS_DASHBOARD, '403'),
  }
};