import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
// @mui
import { Button, Card, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
//components
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { TableHeadCustom } from '../../../components/table';
import ConfirmDialog from '../../../components/confirm-dialog';
import Iconify from '../../../components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import ThreeDotsWave from '../../../components/animate/ThreeDotsWave';
import TableInstruction from '../../../components/table/TableInstruction';
import CSVTableRow from './CSVTableRow';
import { useSnackbar } from '../../../components/snackbar';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getExternalRecipients } from '../../../redux/slices/recipients';
import { batchCreateInvoices, selectTransfers } from '../../../redux/slices/invoices';
// constants
import { CSV_PAYMENTS_INSTRUCTION } from '../../../constants/instructions';
// utils
import { parseCSVFileMassPayments } from '../../../utils/parseCSVFile';
import { parseXLSXFileMassPayments } from '../../../utils/parseXLSXFiles';

const TABLE_HEAD = [
  { id: 'number', label: '#', align: 'left', sorting: true },
  { id: 'invoiceNumber', label: 'ID/Bill number', align: 'left', sorting: true },
  { id: 'recipient', label: 'Name', align: 'left', sorting: false },
  { id: 'wallet', label: 'Wallet', align: 'left', sorting: true },
  { id: 'chain', label: 'Currency', align: 'left', sorting: true },
  { id: 'total', label: 'Amount', align: 'left', width: 140 },
];

const PreviewForm = () => {
  const [listData, setList] = useState<null | any[]>(null);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [openConfirm, setOpenConfirm] = useState(false);

  const { network } = useSelector((state) => state.recipients);
  const [isLoading, setLoading] = useState(false);
  const listRecipients = network?.content ?? [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExternalRecipients({ size: 500 }))
  }, [dispatch]);

  const ethRegExp = /^0x[A-Za-z0-9]*$/;
  const trxRegExp = /^T[A-Za-z0-9]*$/;

  const isValidateItem = (item: any) => {
    if (item?.currency?.includes('ERC20')) {
      return ethRegExp.test(item?.address) && item?.address?.length === 42
    } else if (item?.currency?.includes('TRC20')) {
      return (trxRegExp.test(item?.address) && item?.address?.length === 34)
    }
    return false;
  }

  const changeHandler = async (event: any) => {
    const file = event.target.files[0];
    setList(null);

    const fileType = file.name.split('.').pop().toLowerCase();

    if (fileType === 'csv') {
      parseCSVFileMassPayments(file, setList, isValidateItem, listRecipients);
    } else if (fileType === 'xls' || fileType === 'xlsx') {
      parseXLSXFileMassPayments(file, setList, isValidateItem, listRecipients);
    } else {
      enqueueSnackbar('Unsupported file format. Please upload a CSV, XLS, or XLSX file.', { variant: 'error' });
    }
  };

  const fileInputRef = useRef(null);

  const handleClickAttach = () => {
    const { current } = fileInputRef;
    if (current) {
      // @ts-ignore
      current.value = '';
      // @ts-ignore
      current?.click();
    }
  };

  const rightList = listData?.filter(item => item.isAddressValid && item.isTotalValid && item.recipient && item.recipient?.addresses?.approved) ?? [];
  const count = listData ? listData?.length : 0;
  const countInvalid = listData?.filter(item => !item.isAddressValid || !item.isTotalValid || !item.recipient || !item?.recipient?.addresses?.approved)?.length ?? 0;

  const handleCreate = () => {
    if (countInvalid > 0) {
      setOpenConfirm(true);
    } else handleConfirm();
  }

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  }

  const handleConfirm = () => {
    setLoading(true);
    handleCloseConfirm();
    dispatch(batchCreateInvoices(rightList?.map((invoice) => ({
      invoiceNumber: invoice?.id || invoice?.invoiceNumber
        ? (invoice?.id || invoice?.invoiceNumber)
        : undefined,
      invoiceItems: [{
        title: 'Items 1',
        price: invoice?.amount,
        quantity: 1,
        total: invoice?.amount,
      }],
      externalRecipientId: invoice?.recipient?.id,
      cryptoCurrencyAddressId: invoice?.recipient?.addresses?.id,
      dueDate: moment(Date.now()).add(1, 'days').format('YYYY-MM-DD'),
      invoiceDate: moment(Date.now()).format('YYYY-MM-DD'),
      paymentMethod: "CRYPTO",
      status: "DRAFT",
      tax: 0,
      discount: 0,
      total: invoice?.amount,
    })))).then((res) => {
      dispatch(selectTransfers(res))
      navigate(PATH_DASHBOARD.payments.transfer);
      console.log(res);
    })
  }

  return (
    <>
      <CustomBreadcrumbs
        heading="File upload"
        links={[
          {
            name: 'Payments',
            href: PATH_DASHBOARD.payments.root,
          }
        ]}
        action={(
          <Stack direction="row" justifyContent="center" width={1} alignItems="center" p={!listData?.length ? 0 : 2}>
            <input
              ref={fileInputRef}
              type="file"
              name="file"
              accept=".csv, .xlsx, .xls"
              style={{ display: 'none' }}
              onChange={changeHandler}
            />
            <Button
              size="large"
              startIcon={<Iconify icon="material-symbols:upload" />}
              onClick={handleClickAttach}
              variant="outlined"
              sx={{ minWidth: 220, textTransform: 'none' }}
            >
              Upload file
            </Button>
          </Stack>
        )}
      />
      <Card sx={{ height: isLoading ? '60vh' : 'auto' }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          p={!listData?.length ? 0 : 2}
        >
          <Stack spacing={1}>
            {count > 0 && (
              <Typography variant="subtitle2">{listData?.length} rows loaded</Typography>
            )}
            {countInvalid > 0 && (
              <Typography  sx={{ color: (theme) => theme.palette.error.main }} variant="subtitle2">{countInvalid} rows invalid</Typography>
            )}
          </Stack>
          {count > 25 && (
            <Button
              size="large"
              onClick={handleCreate}
              variant="contained"
              sx={{ minWidth: 220 }}
              disabled={!rightList?.length || isLoading}
            >
              Next
            </Button>
          )}
        </Stack>
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: '#f0fff97a'
          }}>
            <ThreeDotsWave />
          </Stack>
        ) : (
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={'small'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                  rowCount={listData?.length || 0}
                />

                <TableBody>
                  {listData
                    ?.map((row, index) => (
                      <CSVTableRow
                        index={index}
                        key={row.id}
                        row={row}
                      />
                  ))}

                  <TableInstruction
                    isNotFound={!listData?.length}
                    type="payment"
                    content={CSV_PAYMENTS_INSTRUCTION}
                    action={(
                      <Stack direction="row" justifyContent="center" width={1} alignItems="center" sx={{ p: 2 }}>
                        <Button
                          size="large"
                          startIcon={<Iconify icon="material-symbols:upload" />}
                          onClick={handleClickAttach}
                          variant="outlined"
                          sx={{ minWidth: 220, textTransform: 'none' }}
                        >
                          Upload file
                        </Button>
                      </Stack>
                    )}
                  />
                </TableBody>

              </Table>
            </Scrollbar>
          </TableContainer>
        ) }
        <Stack sx={{ px: 2, py: 2 }} justifyContent="flex-end" alignItems="flex-end">
          {(!isLoading && count > 0) && (
            <Button
              size="large"
              onClick={handleCreate}
              variant="contained"
              sx={{ minWidth: 220 }}
              disabled={!rightList?.length || isLoading}
            >
              Next
            </Button>
          )}
        </Stack>
      </Card>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Proceed to payment"
        content="Rows with errors will not be processed"
        action={
          <Button variant="contained" color="success" onClick={handleConfirm}>
            Continue
          </Button>
        }
      />
    </>
  )
}

export default PreviewForm;