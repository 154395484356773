import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const QRCodePage = Loadable(lazy(() => import('../pages/auth/QRCodePage')));

// DASHBOARD: INVOICE
export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/invoice')));
export const InvoiceInboxPage = Loadable(lazy(() => import('../pages/dashboard/inbox')));
export const InvoiceDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/invoice/DetailsPage'))
);
export const InvoiceCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/invoice/CreatePage'))
);
export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/invoice/EditPage')));


// DASHBOARD: USER
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));

// DASHBOARD: Payers
export const MyPayers = Loadable(lazy(() => import('../pages/dashboard/payer/index')));
export const MyPayersDetails = Loadable(lazy(() => import('../pages/dashboard/payer/Details')));
export const ExternalPayersDetails = Loadable(lazy(() => import('../pages/dashboard/payer/ExternalDetails')));

//
export const BecomePayer = Loadable(lazy(() => import('../pages/dashboard/payer/BecomePayer')));

// DASHBOARD: Recipients
export const MyRecipient = Loadable(lazy(() => import('../pages/dashboard/recipient')));
export const MyRecipientDetails = Loadable(lazy(() => import('../pages/dashboard/recipient/Details')));
export const MassRecipients = Loadable(lazy(() => import('../pages/dashboard/recipient/MassRecipients')));

export const Parties = Loadable(lazy(() => import('../pages/dashboard/Parties')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));
export const Wallets = Loadable(lazy(() => import('../pages/dashboard/WalletsPage')));
export const Billing = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page400 = Loadable(lazy(() => import('../pages/Page400')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
// export const FAQs = Loadable(lazy(() => import('../pages/FaqsPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// OUTBOX
export const PaymentPage = Loadable(lazy(() => import('../pages/dashboard/payments')));
export const PaymentMassPayout = Loadable(lazy(() => import('../pages/dashboard/payments/FastFlowCreateEditPage')));
export const PaymentQuickPayout = Loadable(lazy(() => import('../pages/dashboard/payments/QuickPayout')));
export const PaymentNewInvoice = Loadable(lazy(() => import('../pages/dashboard/payments/CreatePage')));
export const PaymentEditInvoice = Loadable(lazy(() => import('../pages/dashboard/payments/EditPage')));
export const PaymentDetailsInvoice = Loadable(lazy(() => import('../pages/dashboard/payments/DetailsPage')));

export const StartPage = Loadable(lazy(() => import('../pages/StartPage')));

// OVERVIEW
export const OverviewPage = Loadable(lazy(() => import('../pages/dashboard/overview')));



export const AdminOverviewPage = Loadable(lazy(() => import('../pages/dashboard/admin/overview')));
export const AdminInvoicesPage = Loadable(lazy(() => import('../pages/dashboard/admin/invoices')));
